import * as React from "react"
import "./land.scss"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Lands from "./components/listings"



const LandListings = () => (
  <Layout>
    <Seo title="Royal Palm Destinations | Royal Palm Destinations LAnds " />
    <Lands/>
  </Layout>
)

export default LandListings;
